/* src/custom-dropdown.css */
.custom-dropdown {
  color: #000000; /* Text color */
  background-color: #ffffff; /* Background color */
  border: 1px solid #cccccc; /* Border color */
  padding: 8px; /* Padding */
  border-radius: 4px; /* Border radius */
  width: 100%; /* Full width */
}


.auto-complete-wrapper {
  width: 50%; /* Adjust this percentage as per your design */
  margin: 0 auto;
  position: relative; /* Ensure relative positioning for dropdown alignment */
}

.full-width {
  width: 50% !important;
}

.p-autocomplete-panel {
  width: 50% !important;
  box-sizing: border-box; /* Ensure it includes padding and border in width calculation */
}
.p-autocomplete-items {
  padding: 5 !important; /* Remove any padding */
}

.p-autocomplete-item {
  padding-left: 5 !important; /* Ensure items start from the left without padding */
}
