/* .toast {
  position: absolute;
  top: 25px;
  right: 30px;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  opacity: 0;
  transform: translateX(100%); 
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}  */

/* .toast.show {
  opacity: 1;
  transform: translateX(0); 
} */

/* .toast.success { background-color: #4CAF50; }
.toast.error { background-color: #f44336; }
.toast.info { background-color: #2196F3; }
.toast.warning { background-color: #ff9800; } */



/* Toast Styles */
.toast {
  position: absolute;
  bottom: 25px;
  right: 30px;
  border-radius: 12px;
  background: #fff; /* Keep the main background white */
  padding: 15px 20px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  max-width: 300px;
  color: #333; /* Text color */
}

.toast.show {
  opacity: 1;
  transform: translateX(0);
}

/* Toast Types */
.toast.success { border-left: 6px solid #4CAF50; }
.toast.error { border-left: 6px solid #f44336; }
.toast.info { border-left: 6px solid #2196F3; }
.toast.warning { border-left: 6px solid #ff9800; }

/* Toast Content Layout */
.toast .toast-content {
  display: flex;
  align-items: center;
}

.toast-content .toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  margin-right: 10px;
}

/* Icon Background Colors */
.toast.success .toast-icon { background-color: #4CAF50; }
.toast.error .toast-icon { background-color: #f44336; }
.toast.info .toast-icon { background-color: #2196F3; }
.toast.warning .toast-icon { background-color: #ff9800; }

.toast-content .message {
  display: flex;
  flex-direction: column;
}

.message .text-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.message .text-body {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

/* Close Button */
.toast .close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.toast .close:hover {
  opacity: 1;
}

/* Progress Bar */
.toast .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  animation: progress 3s linear forwards;
}

/* Progress Bar Colors */
.toast.success .progress-bar { background-color: #4CAF50; }
.toast.error .progress-bar { background-color: #f44336; }
.toast.info .progress-bar { background-color: #2196F3; }
.toast.warning .progress-bar { background-color: #ff9800; }

@keyframes progress {
  from { width: 100%; }
  to { width: 0%; }
}
