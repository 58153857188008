.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
}


.authentication-wrapper .authentication-inner {
  width: 100%;
}
.authentication-wrapper.authentication-basic {
  align-items: center;
  justify-content: center;
}
.authentication-wrapper .auth-cover-illustration {
  z-index: 1;
  max-inline-size: 38rem;
}
.authentication-wrapper .authentication-image-object-left {
  position: absolute;
  bottom: 6%;
  inset-inline-start: 4%;
}
.authentication-wrapper .authentication-image-object-right {
  position: absolute;
  bottom: 7%;
  inset-inline-end: 4%;
}
.authentication-wrapper .authentication-image {
  z-index: -1;
  inline-size: 100%;
  position: absolute;
  inset-inline-start: 0;
  bottom: 0;
}
.authentication-wrapper .authentication-image-model {
  width: 768px;
}
.authentication-wrapper.authentication-cover {
  align-items: flex-start;
}
.authentication-wrapper.authentication-cover .authentication-inner {
  min-height: 100vh;
  position: relative;
}
html:not([dir="rtl"])
  .authentication-wrapper.authentication-cover
  .authentication-image {
  inset-inline-start: unset;
}
.authentication-wrapper.authentication-cover .authentication-image-tree {
  position: absolute;
  bottom: 4rem;
  left: 3rem;
}
.authentication-wrapper.authentication-basic .authentication-inner {
  max-width: 460px;
}
.authentication-wrapper .auth-input-wrapper .auth-input {
  max-width: 50px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 150%;
}
@media (max-width: 575.98px) {
  .authentication-wrapper .auth-input-wrapper .auth-input {
    font-size: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .authentication-cover .authentication-image-model {
    position: relative;
    width: 575px;
    top: 1rem;
  }
}
#twoStepsForm .fv-plugins-bootstrap5-row-invalid .form-control {
  border-width: 2px;
  border-color: #ff4c51;
}
@media (max-width: 575.98px) {
  .numeral-mask-wrapper .numeral-mask {
    padding: 0 !important;
  }
  .numeral-mask:not(:focus) {
    margin-inline: 1px !important;
  }
  .numeral-mask:focus {
    margin-inline: auto !important;
  }
}
.light-style .authentication-wrapper .authentication-bg {
  background-color: #fff;
}
.dark-style .authentication-wrapper .authentication-bg {
  background-color: #312d4b;
}
[dir="rtl"] .authentication-image-tree {
  right: 3rem;
  left: auto;
}
@media (max-width: 575.98px) {
    .authentication-wrapper .auth-input-wrapper {
      justify-content: center; /* Center align inputs */
      gap: 40px; /* Add space between inputs */
    }
  
    .authentication-wrapper .auth-input-wrapper .auth-input {
      width: 40px; 
      height: 40px; 
      font-size: 1.125rem; 
      padding-left: 0; 
      padding-right: 0;
    }
  
    .authentication-wrapper .auth-input-wrapper .numeral-mask {
      margin-inline: 2px !important; 
    }
  }
  