.section-py {
  padding: 6.25rem 0;
}
@media (max-width: 1199.98px) {
  .section-py {
    padding: 5rem 0;
  }
}
@media (max-width: 767.98px) {
  .section-py {
    padding: 3rem 0;
  }
}
.landing-hero {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding-top: 8.125rem;
}
.landing-hero .hero-title {
  font-size: 1.7em;
  font-weight: 700;
  line-height: 1.16;
}
@media (max-width: 991.98px) {
  .landing-hero .hero-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 575.98px) {
  .landing-hero .hero-title {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .landing-hero .hero-text-box {
    max-width: 545px;
    margin: 0 auto;
  }
}
.landing-hero .hero-animation-img {
  margin-bottom: -16rem;
}
@media (max-width: 991.98px) {
  .landing-hero .hero-animation-img {
    margin-bottom: -10rem;
  }
}
@media (max-width: 575.98px) {
  .landing-hero .hero-animation-img {
    margin-bottom: -4rem;
  }
}
.landing-hero .hero-animation-img .hero-dashboard-img img {
  width: 85%;
  margin: 0 auto;
}
.landing-hero .hero-animation-img .hero-elements-img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.landing-hero .hero-animation-img .hero-elements-img img {
  width: 100%;
}
.landing-features {
  padding-top: 4%;
}
@media (max-width: 991.98px) {
  .landing-features {
    padding-top: 4%;
  }
}
@media (max-width: 575.98px) {
  .landing-features {
    padding-top: 4%;
  }
}
.landing-features .features-icon-wrapper .features-icon-box .features-icon {
  height: 5.125rem;
  width: 5.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 50rem;
  transition: all 0.3s ease-in-out;
}
.landing-features
  .features-icon-wrapper
  .features-icon-box
  .features-icon-description {
  max-width: 310px;
  margin: 0 auto;
}
.landing-reviews .swiper-reviews-carousel .swiper {
  padding-bottom: 3rem;
  overflow: visible !important;
}
@media (max-width: 991.98px) {
  .landing-reviews .swiper-reviews-carousel .swiper {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .landing-reviews .swiper-reviews-carousel .swiper {
    padding: 0 1rem 2rem;
  }
}
.landing-reviews .swiper-reviews-carousel .swiper-slide {
  height: auto;
  padding: 1rem 0;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.landing-reviews .swiper-reviews-carousel .swiper-slide.swiper-slide-active {
  opacity: 1;
  padding: 0;
}
.landing-reviews .swiper-reviews-carousel .swiper-pagination {
  bottom: 0;
}
.landing-reviews
  .swiper-reviews-carousel
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 0.625rem;
  height: 0.625rem;
  opacity: 1;
}
.landing-reviews .swiper-reviews-carousel .client-logo {
  height: 1.75rem;
  object-fit: contain;
}
.landing-reviews .swiper-logo-carousel .swiper {
  max-width: 60rem;
}
.landing-reviews .swiper-logo-carousel .swiper .swiper-slide {
  display: flex;
  justify-content: center;
}
.landing-reviews .swiper-logo-carousel .swiper .client-logo {
  height: 1.75rem;
  max-width: 95%;
  object-fit: contain;
}
.landing-team .team-image-box {
  height: 11.5625rem;
  border-top-left-radius: var(--bs-card-border-radius);
  border-top-right-radius: var(--bs-card-border-radius);
}
.landing-team .team-image-box .card-img-position {
  height: 15rem;
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .landing-team .team-image-box .card-img-position {
    height: 13rem;
  }
}
@media (max-width: 575.98px) {
  .landing-team .team-image-box {
    height: 11rem;
  }
}
.landing-team .card .team-media-icons i {
  transition: all 0.2s ease-in-out;
}
.landing-pricing .noUi-target,
.landing-pricing .noUi-target .noUi-connect {
  border-radius: 0.625rem;
}
.landing-pricing #slider-pricing {
  height: 0.375rem;
}
@media (min-width: 992px) {
  .landing-pricing #slider-pricing {
    width: 75%;
    margin: 0 auto;
  }
}
.landing-pricing .card .card-header,
.landing-pricing .card .card-body {
  padding: 2rem;
}
.landing-fun-facts .fun-facts-icon {
  width: 5.125rem;
  height: 5.125rem;
  transition: all 0.3s ease-in-out;
}
.landing-fun-facts .fun-facts-text {
  font-size: 2.125rem;
}
.landing-faq .faq-image {
  max-width: 320px;
  width: 80%;
}
.landing-contact .bg-icon-left .tagline,
.landing-contact .bg-icon-left .title {
  opacity: 0.92;
}
.landing-contact .bg-icon-left .description {
  opacity: 0.78;
}
.light-style
  .landing-reviews
  .swiper-reviews-carousel
  .swiper-pagination
  .swiper-pagination-bullet {
  background-color: #eae9ec;
}
.light-style
  .landing-reviews
  .swiper-reviews-carousel
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #c0bec5 !important;
}
.light-style .landing-team .card:hover .team-media-icons i[class*="facebook"] {
  color: var(--bs-facebook);
}
.light-style .landing-team .card:hover .team-media-icons i[class*="twitter"] {
  color: var(--bs-twitter);
}
.light-style .landing-team .card:hover .team-media-icons i[class*="linkedin"] {
  color: var(--bs-linkedin);
}
.dark-style
  .landing-reviews
  .swiper-reviews-carousel
  .swiper-pagination
  .swiper-pagination-bullet {
  background-color: #433f5d;
}
.dark-style
  .landing-reviews
  .swiper-reviews-carousel
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #686480 !important;
}
.dark-style .landing-team .card:hover .team-media-icons i[class*="facebook"] {
  color: var(--bs-facebook);
}
.dark-style .landing-team .card:hover .team-media-icons i[class*="twitter"] {
  color: var(--bs-twitter);
}
.dark-style .landing-team .card:hover .team-media-icons i[class*="linkedin"] {
  color: var(--bs-linkedin);
}
[dir="rtl"] .landing-team .team-image-box .card-img-position {
  transform: translateX(50%) !important;
}
