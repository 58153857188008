/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.light-style .menu .app-brand.demo {
  height: 64px;
}

.dark-style .menu .app-brand.demo {
  height: 64px;
}

.app-brand-logo.demo svg {
  width: 30px;
  height: 24px;
}

.app-brand-text.demo {
  font-size: 1.7em;
}

.authentication-wrapper .app-brand-text.demo {
  font-size: 1.5rem;
  text-transform: capitalize;
}
/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1.25rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/* Dropdown buttons going out of small screens */
@media (max-width: 576px) {
  #dropdown-variation-demo .btn-group .text-truncate {
    width: 300px;
    position: relative;
  }
  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }
}
/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1.25rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1.25rem;
}


.xyz{
  color: aqua;
}

.btn-success {
  background-color: rgb(7, 123, 7);
  border-color: rgb(3, 109, 3);
  color: white;
}

.btn-success:disabled {
  background-color: rgb(127, 240, 127);
  border-color: rgb(127, 240, 127);
  color: white;
}
.fw-bold1 {
  font-weight: bold;
  font-size: 14px; /* Adjust the font size as needed */
}
.text-primary1 {
color: rgb(111, 111, 255);
}
.active1 {
color:   #8c57ff;;
}
.menu-item1 {
  color: grey;
}

.menu-item.active1 {
  color: blue;
}
.custom-red-border {
  border: 2px solid red;
  border-radius: 50px; /* Adjust border-radius as needed */
  color: red;
  background-color: transparent;
}

.even-row {
  background-color: #f9f9f9; /* Light grey color for even rows */
}

.odd-row {
  background-color: #ffffff; /* White color for odd rows */
}
.custom-grey-button {
  color: grey !important;  /* Important is used to override any conflicting styles */
  border: 1px solid grey;
}
.custom-btn-action1{
  width:32px;
  height: 32px;
 
}

.swiper-wrapper {
  display: flex;
  flex-direction: row;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust as needed */
}


.custom-nav-link.active {
  border-bottom: 3px solid #8c57ff; 
  color: #8c57ff !important; /* Overwrite default color */
  font-weight: bolder !important; /* Make active link bold */
}

.custom-nav-link {
  font-weight:normal !important; /* Lighter weight for inactive */

}
.hover-bg-secondary:hover {
  background-color: #f8f9fa; 
}
.custom-col {
  height: 40px;
  width: 100%;  
  white-space: nowrap; 
}

.custom-col-content {
  padding-right: 5px;
  overflow: hidden;   
  text-overflow: ellipsis; 
}
.border-bottom-rounded {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
 
  border-left: 1px solid #dfdfe3; 
  border-right: 1px solid #dfdfe3; 
  border-bottom: 1px solid #dfdfe3; 
  
  overflow: hidden;
  background-color: #fefefe;
}
.second-nav {
  box-shadow: 0px 4px 8px -4px rgba(46, 38, 61, 0.42);
}



.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 270px;
  height: 100%;
  background-color: #f4f5fa;
  color: black;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar.active {
  left: 0;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 400px;
}
@media (min-width: 768px) {
  /* For tablet screens and above */
  .sidebar-nav {
    margin-bottom: 600px;
  }
}
.sidebar-nav li {
  margin-bottom: 10px;
  position: relative;
}

.sidebar-nav li a {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  text-decoration: none;
  color: black;
  padding: 10px;
  border-radius: 25px; 
  transition: background-color 0.3s ease-in-out;
}

.sidebar-nav li a:hover {
  background-color: #8c57ff; 
  color:white;
}

.sidebar-nav li i.sidebar-arrow {
  margin-left: auto;
  color: gray;
  transition: color 0.3s ease-in-out;
}

.sidebar-nav li a:hover i.sidebar-arrow {
  color: white; /* Arrow turns white on hover */
}

/* Close icon styling */
.close-icon {
  cursor: pointer;
  font-size: 24px;
  color: #8a8d93;
}

.close-icon:hover {
  color: #8a8d93;
}

/* Overlay for when sidebar is open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(202, 201, 201, 0.732);
  z-index: 900;
}


.footer-link i {
  color: #a5a8ab;
  transition: color 0.3s ease;
}


.footer-link i.ri-facebook-circle-fill:hover {
  color: #1877F2;
}


.footer-link i.ri-instagram-line:hover {
  color: #de45a8;
}


.footer-link i.ri-youtube-line:hover {
  color: #FF0000;
}


.footer-link i.ri-linkedin-fill:hover {
  color: #0077B5;
}


.footer-link i.ri-threads-line:hover {
  color: #000000;
}


.footer-link i.ri-twitter-x-line:hover {
  color: #1DA1F2; 
}

.footer-link i.ri-telegram-line:hover {
  color: #0088CC;
}

.footer-link i.ri-pinterest-line:hover {
  color: #E60023;
}

/* Reduce dropdown width on mobile */
@media (max-width: 576px) {
  .dropdown-menu-mobile {
    width: 150px; /* Adjust the width as per your requirement */
  }
}

.rotate {
  animation: rotate 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-datatable .custom-column-border {
  word-wrap: break-word; /* Allow long text to wrap */
  max-width: 100%; /* Prevent overflowing content */
}

@media (max-width: 576px) {
  .p-datatable .custom-column-border {
    min-width: 100px; /* Adjust minimum width for columns in mobile view */
  }
}


.sidebar-footer1 {
  margin-top: 20px; 
  font-size: 14px; 
  color: #6c757d;   
}
